#root{
    height: 100%;
  }
  .mos{
    height: 100%;
  }
  .sider{
    .ant-layout-sider-children{
      display: flex;
      flex-direction: column;
    }
    .logo{
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow:0 1px 4px rgba(0,21,41,.08);
      position: relative;
      z-index: 999;
      flex-shrink: 0;
      text-align: center;
      cursor: pointer;
      img{
        display: block;
        max-width: 80%;
        max-height: 90%;
      }
    }
    .ant-menu-root{
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
  .mos-right{
    overflow: hidden;
  }
  .header{
    background-color: #fff !important;
    height: 50px !important;
    // line-height: 50px;
    box-shadow:0 1px 4px rgba(0,21,41,.08);
    display: flex;
    justify-content: space-between;
    z-index: 999;
    >div{
      display: flex;
      align-items: center;
      .ant-menu{
        height: 100%;
        display: flex;
        line-height: 50px;
        flex-grow: 1;
      }
      .ant-menu-horizontal{
        border-bottom:none;
      }
      .ant-input-affix-wrapper{
        width: 200px;
      }
      .btn{
        height: 100%;
        min-width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: initial;
        transition-duration: 300ms;
        padding: 0 10px;
        .ant-avatar{
          flex-shrink: 0;
        }
        .name{
          margin-left: 5px;
          flex-shrink: 0;
        }
        >span{
          display: block;
        }
        &:hover{
          background-color: rgb(245, 245, 245);
        }
      }
      .organization{
        span{
          margin: 0 5px;
        }
      }
    }
    .menu{
      flex-grow: 1;
    }
  }