._FormListJson {
    >.add {
        width: 100%;
    }

    .ant-table-empty {
        .ant-table-tbody {
            display: none;
        }
    }
}